import styled from "styled-components";
import { Colors, Rem } from "../../../commons/Theme";
import { motion } from "framer-motion";

export const LineContainer = styled.div`
  width: 2px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
`;

export const AnimatedLine = styled.div`
  transform-origin: 50% 0;
  background: ${Colors.yellow};

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;

export const StyledTopDotContainer = styled(motion.div)`
  position: absolute;
  top: ${({ dotType }) => (dotType === "mute" ? "-18px" : "-13px")};
  left: 1px;
  transform: translateX(-50%);
`;

export const StyledBottomDotContainer = styled(motion.div)`
  position: absolute;
  bottom: -15px;
  left: 1px;
  transform: translateX(-50%);
`;

export const StyledMuteButtonContainer = styled.div`
  cursor: pointer;

  /* background-color: ${({ muteStatus }) => (muteStatus ? "black" : "red")}; */

  & > span {
    width: ${Rem(20)};
    height: ${Rem(20)};

    padding: 2px;
    border: 2px solid ${Colors.yellow};
    border-radius: ${Rem(20)};

    position: relative;

    &::after {
      content: "";

      width: 100%;
      height: 2px;

      position: absolute;
      top: 50%;
      left: 0;

      transform: translateY(-50%) rotate(45deg);

      background-color: ${Colors.yellow};

      visibility: ${({ muteStatus }) => (muteStatus ? "visible" : "hidden")};
    }

    & > svg {
      height: 100%;
    }
  }
`;
